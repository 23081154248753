<template>
  <main id="learn">
    <!-- Video Section -->
    <v-sheet id="steps">
      <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
      <v-container class="pb-8" style="max-width: 1280px">
        <v-row justify="center">
          <v-col cols="12">
            <div class="text-h4 font-weight-light my-4">
              Latest from My One Rule
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <v-card class="rounded-lg" style="overflow: hidden" elevation="4">
              <v-img :aspect-ratio="16/9" width="100%">
                <iframe src="https://www.youtube.com/embed/0vdQZ8rQKDQ" frameborder="0" style="width: 100%; height: 100%" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen=""></iframe>
              </v-img>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
                <v-spacer></v-spacer>
                <v-btn class="" color="primary" href="https://shop.myonerule.com/" block large>
                  See More Videos
                  <v-icon right dark>
                    mdi-youtube
                  </v-icon>
                </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </main>
</template>

<script>
export default {
  name: "Learn More",
  data: () => ({
    /*
    slider: 0,
    videos: [
      { src: "https://www.youtube-nocookie.com/embed/eg1Uj8K9qII?rel=0" },
      { src: "https://www.youtube-nocookie.com/embed/D5NMcAmvlHc?rel=0" },
      { src: "https://www.youtube-nocookie.com/embed/eg1Uj8K9qII?rel=0" }
    ]
    */
  })
}
</script>